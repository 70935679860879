import logo from './logo.svg';
import './App.css';
import Header from './components/header/Header';
import Home from './pages/Home';
import ParticlesSction from './components/ParticlesSection/ParticlesSction';
import ParticlesBg from './components/ParticlesBg';
import Footer from './components/footer/Footer';
import About from './pages/About';
import {Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Priceing from './pages/Priceing';
import Equipment from './pages/Equipment';
import Education from './pages/Education';
import Events from './pages/Events';
import Contact from './pages/Contact';
import ScrollToTop from './components/ScrollToTop';
import { HashRouter } from 'react-router-dom'

function App() {
  return (
    <HashRouter className="App">
      <Header />
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/o-nas">
          <About />
        </Route>
        <Route path="/cennik">
          <Priceing />
        </Route>
        <Route path="/sprzet">
          <Equipment />
        </Route>
        <Route path="/edukacja">
          <Education />
        </Route>
        <Route path="/shr-events">
          <Events />
        </Route>
        <Route path="/kontakt">
          <Contact />
        </Route>
      </Switch>

      <Footer />
    </HashRouter>
  );
}

export default App;
