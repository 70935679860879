const GrayBox = ({ children, title, customClasses, imgSrc }) => {
  return (
    <div className={`w-full md:w-4/12 px-8 text-center ${customClasses || ''}`}>
      <div className="relative flex flex-col min-w-0 break-words bg-zinc-300 w-full mb-8 shadow-lg cutted-bottom">
        <div className="px-4 py-5 flex-auto">
          {imgSrc && (
            <div className="text-white p-5 text-center inline-flex items-center justify-center w-24 h-24 mb-5 shadow-lg rounded-full bg-white">
              <img src={imgSrc} alt={title} />
            </div>
          )}
          <h6 className="text-xl font-semibold">{title}</h6>
          {children}
        </div>
      </div>
    </div>
  );
};

export default GrayBox;
