import video from './../assets/video/video1.mp4';
import mainImg from './../assets/img/SHR1.jpeg';
import logo from './../assets/img/logo.png';
import microphone from './../assets/img/microphone.png';
import musicEqualizer from './../assets/img/music-equalizer.png';
import concert from './../assets/img/concert.png';
import ParticlesSction from '../components/ParticlesSection/ParticlesSction';
import Slider from '../components/slider/Slider';
import BlackButton from '../components/BlackButton';
import ScatterGallery from '../components/scatterGallery/ScatterGallery';
import ImageBgSection from '../components/imageBgSection/ImageBgSection';
import GrayBox from '../components/GrayBox';

const Home = () => {
  return (
    <div>
      <section className="min-h-full relative overflow-hidden video-section">
        <Slider />
        <div className="gradient gradient-1"></div>
        <div className="gradient gradient-2"></div>
      </section>
      <section className="pb-10 pt-20 -mt-24 overflow-hidden relative z-10 main-section">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <img src={logo} />
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-white">
                Studio nagraniowe w Gdyni, Polska. Studio stworzone przez
                freaków z pasji i miłości do muzyki. Wyposażone w topowy sprzęt
                pro-audio. Studio jest wentylowane. Z miłości do natury główne
                materiały które posłużyły do wykonania studia to cegła i drewno.
                W 99% wykonane ręcznie. Nie wykorzystujemy gotowych rozwiązań
                aby stworzyć jak najlepszą przestrzeń akustyczną i wizualną.
              </p>
              {/* <a
                href="/"
                className=" mt-8 font-bold text-gray-500 px-8 py-4 rounded-md bg-gray-50 hover:bg-gray-400 hover:text-gray-50">
                Check Cookie!
              </a> */}
            </div>
            <div className="w-full md:w-7/12 px-4 -mr-60 ml-auto">
              <img src={mainImg} />
            </div>
          </div>
        </div>
      </section>
      <section
        className="pb-20 bg-zinc-00 -mt-24 overflow-hidden relative"
        style={{ height: '300px' }}>
        <div className="gradient gradient-1 top"></div>
        <div className="gradient gradient-2 top"></div>
      </section>
      <section className="pb-20 mt-24 overflow-hidden relative">
        <div className="container mx-auto px-4">
          <div className="flex flex-row flex-wrap mx-auto">
            <GrayBox title="Nagrania" customClasses="lg:pt-12 pt-6" imgSrc={microphone}>
              <p className="mt-2 mb-4 text-gray-600">
                Zrealizujemy Twoje wymarzone nagranie na najwyższym
                światowym poziomie na najlepszym sprzęcie dostępnym na
                rynku! Od lektorów, solistów, wokalistów, raperów po całe
                zespoły!
              </p>
            </GrayBox>
            <GrayBox title="Mix & master" imgSrc={musicEqualizer}>
              <p className="mt-2 mb-4 text-gray-600">
                Wyedytujemy, zmiksujemy, zmasterujemy Twoje nagranie z najwyższą
                starannością i najlepszą możliwą jakością!
              </p>
            </GrayBox>
            <GrayBox title="Realizacja live" customClasses="lg:pt-12 pt-6" imgSrc={concert}>
              <p className="mt-2 mb-4 text-gray-600">
                Jako SHR Events przygotujemy Twój koncert, event,
                konferencje od A do Z. Nagłośnienie, oświetlenie, dioda i
                konstrukcje sceniczne są również w naszej ofercie!
              </p>
            </GrayBox>
            <BlackButton text="Cennik" link="cennik" />
          </div>
        </div>
      </section>
      <ParticlesSction />
      <section>
        <div className="mx-auto px-4 container" data-color="black">
          <div className="flex flex-row flex-wrap mx-auto">
            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-8 text-center">
              <ScatterGallery />
            </div>
            <div className="w-full md:w-8/12 px-8 text-center">
              <div className="vertical-mid">
                <h3 className="text-5xl font-semibold mb-8">
                  Wyposażenie SHR
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-black">
                  Nasze studio dysponuje wszechstronnym zapleczem sprzętowym.
                  Posiadamy szeroką gamę mikrofonów, gitar, wzmacniaczy oraz
                  duży outbboard. Ponadto mamy dostęp do niezliczonej liczby
                  instrumentów wirtualnych oraz pluginów w programie DAW. Cała
                  lista dostępnego sprzętu znajduje się w zakładce Sprzęt.
                </p>
                <BlackButton text="Sprzęt" link="sprzet" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ImageBgSection />
    </div>
  );
};

export default Home;
