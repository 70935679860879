import ParticlesSction from '../components/ParticlesSection/ParticlesSction';
import GrayBox from '../components/GrayBox';

const Equipment = () => {
  return (
    <div>
      <section
        id="o-nas-1"
        className="pb-10 pt-60 pb-80 -mb-48 overflow-hidden relative z-10 main-section">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto text-center">
              <h1 className="text-xxxl font-semibold text-center">Sprzęt</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-40 pb-20 mt-24 overflow-hidden relative">
        <div className="container mx-auto px-4">
          <h2 className="text-5xl font-semibold mb-8 text-center">Audio Rig</h2>
          <div className="flex flex-row flex-wrap mx-auto">
            <GrayBox title="Digital Rig" customClasses="pt-6">
              <ul className="shr-list">
                <li>Antelope Orion 32+ 3rd Gen (32/32)</li>
                <li>Antelope Orion Studio Synergy Core (12/12)</li>
                <li>Antelope Zen Tour rev 2017 (8/8)</li>
              </ul>
            </GrayBox>
            <GrayBox title="System odsłuchowy" customClasses="pt-6">
              <ul className="shr-list">
                <li>Adam S3V</li>
                <li>Klark Acoustik Jade One mk2</li>
                <li>Yamaha NS40</li>
                <li>Beyerdynamic DT770 x 3</li>
                <li>Beyerdynamic DT880 pro</li>
                <li>Beyerdynamic DT250</li>
                <li>KRK KNS 6400</li>
                <li>Beats Studio Pro</li>
                <li>KZ ZST x 4</li>
                <li>Midas DP48</li>
                <li>Behringer P16</li>
                <li>Midas M32 Core</li>
                <li>Behringer P16-I</li>
              </ul>
            </GrayBox>
            <GrayBox title="Komputer" customClasses="pt-6">
              <ul className="shr-list">
                <li>Apple MacBook Pro 16’” M1Max (64GB Ram)</li>
                <li>Apple MacBook Air M1 (16GB Ram)</li>
              </ul>
            </GrayBox>
            <GrayBox title="Preampy oraz Channel Stripe" customClasses="pt-6">
              <ul className="shr-list">
                <li>48 x Amek-Neve preamp console</li>
                <li>4 x Neve Style 1073 (Yucca Audio A73)</li>
                <li>4 x Gyraf Audio Gyratec (Yucca Audio)</li>
                <li>2 x Symetrix 528</li>
                <li>2 x Trident Series 80</li>
                <li>TF Pro P10 dual channel</li>
                <li>4 x Dual Pre (Yucca Audio transformerless preamp)</li>
              </ul>
            </GrayBox>
            <GrayBox title="Procesory dynamiki" customClasses="pt-6">
              <ul className="shr-list">
                <li>2 x Looptrotter Sa2rate (4 channels)</li>
                <li>
                  SOS Audio Upgrades Dual Monoblock Compressor (custom Smart
                  Research C1 compressor)
                </li>
                <li>2 x SSL style Bus Compressor (Yucca Audio)</li>
                <li>4 x 1176 style compressor (Yucca Audio)</li>
                <li>1 x 1176 style compressor (Klark Technik)</li>
                <li>1 x La2a style compressor (Klark Technik)</li>
                <li>1 x Symetrix 501</li>
                <li>2 x BSS DPR 402 (dual mono/stereo compressor)</li>
                <li>3 x BSS DPR 404 (quad mono/ dual stereo compressor)</li>
                <li>2 x DBX 160A</li>
                <li>2 x Ashley SC50 blackface</li>
                <li>1 x BSS DPR 944 (dual gate, dual compressor)</li>
                <li>1 x Drawmer DS201 (dual gate)</li>
                <li>1 x Drawmer DS404 (quad gate)</li>
                <li>1 x Aphex 108 Easy Rider (dual compressor)</li>
              </ul>
            </GrayBox>
            <GrayBox title="Equalizery oraz FX" customClasses="pt-6">
              <ul className="shr-list">
                <li>
                  2 x Pultec EQP1A style (Yucca Audio OEP transformers for
                  tracking)
                </li>
                <li>
                  2 x Pultec EQP1A style (Yucca Audio Lundahl transformers
                  for mastering)
                </li>
                <li>1 x SPL Stereo Vitalizer</li>
                <li>1 x Aphex Aural Exciter Type III</li>
                <li>1 x TC Electronic M-One XL</li>
                <li>1 x Line 6 Mod Pro</li>
                <li>1 x Line 6 Echo Pro</li>
                <li>1 x Lexicon LXP-15</li>
                <li>1 x Yamaha SPX 990</li>
                <li>1 x TC Electronic TC 2290 Dynamic Digital Delay</li>
                <li>1 x Yamaha Rev 7 Reverberator</li>
              </ul>
            </GrayBox>
          </div>
        </div>
      </section>
      <section className="pt-40 pb-20 mt-24 overflow-hidden relative main-section">
        <div className="container mx-auto px-4">
          <h2 className="text-5xl font-semibold mb-8 text-center text-white">
            Mikrofony
          </h2>
          <div className="flex flex-row flex-wrap mx-auto">
            <GrayBox title="Mikrofony pojemnościowe wielomembranowe" customClasses="pt-6">
              <ul className="shr-list">
                <li>1 x Telefunken U47 (vintage/KK47/VF14m)</li>
                <li>1 x Telefunken ELAM 251E (vintage)</li>
                <li>1 x AKG C12 (vintage/CK12 capsule)</li>
                <li>2 x Slate ML1</li>
                <li>1 x Neumann TLM 103</li>
                <li>2 x sE Electronics sE4400a</li>
                <li>1 x sE Electronics sE2200a</li>
                <li>1 x Oktava ML5000</li>
                <li>1 x MXL V69M</li>
                <li>2 x MXL 990</li>
                <li>2 x MXL 770</li>
                <li>2 x AT2020</li>
              </ul>
            </GrayBox>
            <GrayBox title="Mikrofony pojemnościowe małomembranowe" customClasses="pt-6">
              <ul className="shr-list">
                <li>2 x Groove Tubes GT-40</li>
                <li>2 x Sennheiser MKH-415T (vintage)</li>
                <li>4 x Lewitt LCT140</li>
                <li>2 x Audix ADX51</li>
                <li>5 x Slate ML2</li>
                <li>2 x ATM350</li>
                <li>2 x Rode NT5</li>
                <li>1 x Antelope Edge Note</li>
              </ul>
            </GrayBox>
            <GrayBox title="Mikrofony wstęgowe i dynamiczne" customClasses="pt-6">
              <ul className="shr-list">
                <li>2 x Royer R121</li>
                <li>1 x Mike Ribbon Custom One</li>
                <li>3 x Oktava ML-51 (vintage)</li>
                <li>5 x Sennheiser MD421 (vintage)</li>
                <li>1 x Sennheiser MD441 (vintage)</li>
                <li>2 x Shure Unidyne II 545SD (vintage)</li>
                <li>40 x Shure SM57</li>
                <li>2 x SM7B</li>
                <li>3 x Shure Beta51a</li>
                <li>1 x Shure Beta91a</li>
                <li>1 x AKD D12VRpple</li>
                <li>1 x E602 (vintage)</li>
                <li>1 x Audix D</li>
                <li>2 x Audix D2</li>
                <li>1 x Audix D4</li>
                <li>3 x Audix i5</li>
                <li>3 x Sennheiser e906</li>
                <li>2 x Sennheiser e609</li>
                <li>1 x Shure 565SD Unisphere (Vintage)</li>
                <li>1 x Lewitt DTP 640 Rex</li>
                <li>3 x Lewitt DTP 340</li>
                <li>1 x Lewitt MTP 440 DM</li>
                <li>2 x sE Electronics V3</li>
              </ul>
            </GrayBox>            
          </div>
        </div>
      </section>
      <section className="pt-40 pb-20 mt-24 overflow-hidden relative">
        <div className="container mx-auto px-4">
          <h2 className="text-5xl font-semibold mb-8 text-center">Audio Rig</h2>
          <div className="flex flex-row flex-wrap mx-auto">
            <GrayBox title="Wzmacniacze, kolumny oraz preampy gitarowe i basowe" customClasses="pt-6">
              <ul className="shr-list">
                <li>Mesa Boogie Dual Rectifier 3ch</li>
                <li>Soldano SLO 100 (1994)</li>
                <li>VHT Pitbull</li>
                <li>MLC Subzero MkV</li>
                <li>
                  MLC Subzero custom preamp (Piotr Grudziński - Riverside)
                </li>
                <li>Peavey 6505+</li>
                <li>Peavey 3120</li>
                <li>V-Empire Blue Rumble Mk2</li>
                <li>Impact by Status pre SoundCity 60’</li>
                <li>Randall Rh150 G3</li>
                <li>Hughes & Kettner Matrix 100 head</li>
                <li>Evolution Amber 60</li>
                <li>Orange Micro Terror</li>
                <li>Orange Micro Dark</li>
                <li>Carvin R600 Bass amp</li>
                <li>Tech 21 Sansamp RBI</li>
                <li>BBE BMax</li>
                <li>Laboga 4x12 HH Electronics</li>
                <li>Marshall 1x12 ZW</li>
                <li>Impact by Status pre SoundCity 60’ 1x12 oversized</li>
                <li>MLC Subzer 2x12 WGS Retro30 + WGS ET65</li>
              </ul>
            </GrayBox>
            <GrayBox title="Gitary i basy" customClasses="pt-6">
              <ul className="shr-list">
                <li>Gibson Les Paul Black Beauty (vintage 1958)</li>
                <li>Gibson Les Paul BFG Gary Boore</li>
                <li>Orville by Gibson Les Paul Custom White 1988</li>
                <li>
                  Orville by Gibson Les Paul Standard November Burst 1988
                </li>
                <li>Gibson Explorer Standard</li>
                <li>Fender Stratocaster American Standard</li>
                <li>Fender Stratocaster Japan 1991</li>
                <li>Fender Telecaster Japan 1988</li>
                <li>Schecter California Standard USA</li>
                <li>Schecter V-7 (1999)</li>
                <li>Schecter Custom C7 White</li>
                <li>Schecter C7 white</li>
                <li>Schecter Corsair 2016 Red</li>
                <li>PRS Modern Eagle II</li>
                <li>Jackson RR5</li>
                <li>Mayones LP Custom baritone (Misiak)</li>
                <li>Mayones Regius 7 (2016)</li>
                <li>Mayones Regius 7 (2017)</li>
                <li>Ibanez RGA 8</li>
                <li>Cort KX508</li>
                <li>Adriani Guitars Custom 6</li>
                <li>Adriani Guitars Custom 6 Semi-Hollow Carbon</li>
                <li>Adriani Guitars Custom 7</li>
                <li>Adriani Guitars Custom 6 Fiberglass</li>
                <li>Baton Rouge Blue moon</li>
                <li>Fender Precision Bass Japan 1991</li>
                <li>Music Man Stingray 5</li>
                <li>OLP Stingray 5</li>
                <li>Fender Jazzbass 5 American Deluxe</li>
              </ul>
            </GrayBox>
            <GrayBox title="Zestawy perkusyjne oraz werble" customClasses="pt-6">
              <ul className="shr-list">
                <li>
                  DC series 8 Danny Gottlieb Maple/Walnut signature 22” 8”
                  10” 12” 14” 16”
                </li>
                <li>
                  Tama Superstar Custom Japan Maple Hyperdrive 22” 10” 12”
                  14”
                </li>
                <li>
                  DC series 8 Danny Gottlieb Maple/Walnut signature 14” x
                  4,5”
                </li>
                <li>Tama Superstar Custom Japan Maple 13” x 6,5”</li>
                <li>Ludwig Supraphonic Supersensitive 60’ 14” x 4,5"</li>
                <li>Ludwig Supraphonic Supersensitive 60’ 14” x 6,5”</li>
                <li>Ludwig Black Beauty 14” x 6,5”</li>
                <li>Yamaha Custom Oak 14” x 6,5”</li>
                <li>Tama Metalworks 12” x 6,5”</li>
              </ul>
            </GrayBox>
            <GrayBox title="Instrumenty klawiszowe" customClasses="pt-6">
              <ul className="shr-list">
                <li>Pianino 1905 rok Pianohaus (Niemcy)</li>
                <li>Arturia Keylab 88 mk2 black edition</li>
              </ul>
            </GrayBox>
            <GrayBox title="DAW" customClasses="pt-6">
              <ul className="shr-list">
                <li>Logic Pro X</li>
                <li>Studio One 5</li>
                <li>Reaper</li>
              </ul>
            </GrayBox>
            <GrayBox title="Pluginy" customClasses="pt-6">
              <ul className="shr-list">
                <li>
                  2 x Pultec EQP1A style (Yucca Audio OEP transformers for
                  tracking)
                </li>
                <li>
                  2 x Pultec EQP1A style (Yucca Audio Lundahl transformers
                  for mastering)
                </li>
                <li>1 x SPL Stereo Vitalizer</li>
                <li>1 x Aphex Aural Exciter Type III</li>
                <li>1 x TC Electronic M-One XL</li>
                <li>1 x Line 6 Mod Pro</li>
                <li>1 x Line 6 Echo Pro</li>
                <li>1 x Lexicon LXP-15</li>
                <li>1 x Yamaha SPX 990</li>
                <li>1 x TC Electronic TC 2290 Dynamic Digital Delay</li>
                <li>1 x Yamaha Rev 7 Reverberator</li>
              </ul>
            </GrayBox>
            <GrayBox title="Instrumenty wirtualne" customClasses="pt-6">
              <ul className="shr-list">
                <li>
                  2 x Pultec EQP1A style (Yucca Audio OEP transformers for
                  tracking)
                </li>
                <li>
                  2 x Pultec EQP1A style (Yucca Audio Lundahl transformers
                  for mastering)
                </li>
                <li>1 x SPL Stereo Vitalizer</li>
                <li>1 x Aphex Aural Exciter Type III</li>
                <li>1 x TC Electronic M-One XL</li>
                <li>1 x Line 6 Mod Pro</li>
                <li>1 x Line 6 Echo Pro</li>
                <li>1 x Lexicon LXP-15</li>
                <li>1 x Yamaha SPX 990</li>
                <li>1 x TC Electronic TC 2290 Dynamic Digital Delay</li>
                <li>1 x Yamaha Rev 7 Reverberator</li>
              </ul>
            </GrayBox>
          </div>
        </div>
      </section>
      <ParticlesSction />
    </div>
  );
};

export default Equipment;
