import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const BlackButton = ({ link, text }) => {
  return (
    <Link
      to={link}
      className="block w-max font-bold text-white mt-8 mx-auto px-8 py-4 rounded-md bg-black hover:bg-gray-400 hover:text-gray-50">
      {text}
    </Link>
  );
};

export default BlackButton;
