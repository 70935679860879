import ParticlesSction from '../components/ParticlesSection/ParticlesSction';

const Events = () => {
  return (
    <div>
      <section
        id="o-nas-1"
        class="pb-10 pt-60 pb-80 -mb-48 overflow-hidden relative z-10 main-section">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto text-center">
              <h1 class="text-xxxl font-semibold text-center">SHR Events</h1>
            </div>
          </div>
        </div>
      </section>
      <section
        id="o-nas-2"
        class="pb-10 pt-60 -mt-24 overflow-hidden relative z-10 main-section">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-7/12 px-4 mr-auto ml-auto">
              <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-white">
                SHR STUDIOS znajduje się w spokojnej willowej dzielnicy Gdyni w
                Orłowie 400 metrów od morza w centrum Trójmiejskiego Parku
                Krajobrazowego. Na studio składają się trzy wyizolowane
                pomieszczenia zaadaptowane akustycznie.
              </p>
              <p class="text-lg font-light leading-relaxed mt-0 mb-8 text-white">
                Studio istnieje od 2011 roku. Nasze produkcje grane są między
                innymi w stacjach radiowych takich jak Eska, RMF FM, Radio Zet.
              </p>
              <p class="text-lg font-light leading-relaxed mt-0 mb-8 text-white">
                Studio wyposażone jest w konsoletę Amek-Neve 48ch, świetny
                system odsłuchowy, rewelacyjny zbiór urządzeń peryferyjnych,
                mikrofonów oraz instrumentów. Live room jest idealnym miejscem
                do nagrań dużych rockowych bębnów, kwartetów smyczkowych oraz
                przestrzennych wokali. Z wielką chęcią pomożemy przy bookingu
                noclegu.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        class="pb-20 bg-zinc-00 -mt-24 overflow-hidden relative"
        style={{height: "300px"}}>
        <div class="gradient gradient-1 top"></div>
        <div class="gradient gradient-2 top"></div>
      </section>
      <section class="pb-20 overflow-hidden relative">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-9/12 px-4">
              <h2 class="text-5xl font-semibold mb-8 text-center">
                Masterclass z Kubą „Kikutem” Mańkowskim
              </h2>
              <p class="text-xl font-semibold mb-8">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur at euismod massa, et iaculis turpis. Aenean accumsan,
                arcu eget vehicula venenatis, velit eros aliquam ex, sed
                consectetur est neque vel justo. Proin commodo ipsum in
                sollicitudin consequat. Nunc posuere libero urna, tincidunt
                imperdiet ipsum rhoncus luctus. Suspendisse eros nibh, ornare at
                pharetra non, aliquet quis dui. Fusce ut commodo urna, et
                pharetra nisi. Sed fermentum sapien eu arcu auctor vestibulum.
                Praesent porttitor lacus quis quam porta maximus. Etiam faucibus
                et nisi pretium sollicitudin. Nulla quam mauris, tempus id porta
                ut, tempor nec purus.
              </p>
            </div>
            <div class="w-full md:w-3/12 px-4">
              <img src="/assets/img/portret.jpeg" />
            </div>
          </div>
        </div>
      </section>
      <section class="pb-20 pt-20 overflow-hidden relative main-section">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-12/12 px-4">
              <h2 class="text-5xl font-semibold mb-8 text-center text-white">
                Kursy Kwalifikacji Zawodowych
              </h2>
              <p class="text-xl font-semibold mb-8 text-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur at euismod massa, et iaculis turpis. Aenean accumsan,
                arcu eget vehicula venenatis, velit eros aliquam ex, sed
                consectetur est neque vel justo. Proin commodo ipsum in
                sollicitudin consequat. Nunc posuere libero urna, tincidunt
                imperdiet ipsum rhoncus luctus. Suspendisse eros nibh, ornare at
                pharetra non, aliquet quis dui. Fusce ut commodo urna, et
                pharetra nisi. Sed fermentum sapien eu arcu auctor vestibulum.
                Praesent porttitor lacus quis quam porta maximus. Etiam faucibus
                et nisi pretium sollicitudin. Nulla quam mauris, tempus id porta
                ut, tempor nec purus.
              </p>
            </div>
            <div class="flex flex-wrap items-center">
              <div class="w-full md:w-3/12 px-4">
                <img src="/assets/img/adi.png" />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src="/assets/img/adi.png" />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src="/assets/img/adi.png" />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src="/assets/img/adi.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-20 pb-20 overflow-hidden relative">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-9/12 px-4">
              <h2 class="text-5xl font-semibold mb-8 text-center">
                Roczny Kurs Realizacji Nagrań SHR
              </h2>
              <p class="text-xl font-semibold mb-8">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur at euismod massa, et iaculis turpis. Aenean accumsan,
                arcu eget vehicula venenatis, velit eros aliquam ex, sed
                consectetur est neque vel justo. Proin commodo ipsum in
                sollicitudin consequat. Nunc posuere libero urna, tincidunt
                imperdiet ipsum rhoncus luctus. Suspendisse eros nibh, ornare at
                pharetra non, aliquet quis dui. Fusce ut commodo urna, et
                pharetra nisi. Sed fermentum sapien eu arcu auctor vestibulum.
                Praesent porttitor lacus quis quam porta maximus. Etiam faucibus
                et nisi pretium sollicitudin. Nulla quam mauris, tempus id porta
                ut, tempor nec purus.
              </p>
            </div>
            <div class="w-full md:w-3/12 px-4">
              <img src="/assets/img/martin.jpeg" />
            </div>
            <div class="flex flex-wrap items-center">
              <div class="w-full md:w-3/12 px-4">
                <img src="./assets/img/adi.png" />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src="./assets/img/adi.png" />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src="./assets/img/adi.png" />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src="./assets/img/adi.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ParticlesSction />
    </div>
  );
};

export default Events;
