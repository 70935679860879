import emailjs from 'emailjs-com';
import { useRef } from 'react';
import './form.scss';

const SHRForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_w5mfdhe',
        'template_nnfocv8',
        form.current,
        'oN0OlkKaEDhfPpvR2'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div class="shr-form-wrapper bg-zinc-300 flex flex-col min-w-0 break-words w-2/5 text-center p-8">
      <form ref={form} 
    //   onSubmit={sendEmail}
      >
        <div>
          <label>Name:</label>
          <input type="text" name="to_name" />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" name="from_name" />
        </div>
        <div>
          <label>Message:</label>
          <textarea className="textarea" name="message" />
        </div>
        <input className='block w-max font-bold text-white mx-auto px-8 py-4 rounded-md bg-black hover:bg-gray-400 hover:text-gray-50' type="submit" value="Wyślij" />
      </form>
    </div>
  );
};

export default SHRForm;
