import ParticlesSction from '../components/ParticlesSection/ParticlesSction';
import adrian from '../img/ekipa/adrian.jpg'
import marcin from '../img/ekipa/marcin.jpg'
import dominik from '../img/ekipa/dominik.jpg'

const About = () => {
  return (
    <div>
      <section
        id="o-nas-1"
        className="pb-10 pt-60 pb-80 -mb-48 overflow-hidden relative z-10 main-section">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto text-center">
              <h1 className="text-xxxl font-semibold text-center">O Nas</h1>
            </div>
          </div>
        </div>
      </section>
      <section
        id="o-nas-2"
        className="pb-10 pt-60 -mt-24 overflow-hidden relative z-10 main-section">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-7/12 px-4 mr-auto ml-auto">
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-white">
                SHR STUDIOS znajduje się w spokojnej willowej dzielnicy Gdyni w
                Orłowie 400 metrów od morza w centrum Trójmiejskiego Parku
                Krajobrazowego. Na studio składają się trzy wyizolowane
                pomieszczenia zaadaptowane akustycznie.
              </p>
              <p className="text-lg font-light leading-relaxed mt-0 mb-8 text-white">
                Studio istnieje od 2011 roku. Nasze produkcje grane są między
                innymi w stacjach radiowych takich jak Eska, RMF FM, Radio Zet.
              </p>
              <p className="text-lg font-light leading-relaxed mt-0 mb-8 text-white">
                Studio wyposażone jest w konsoletę Amek-Neve 48ch, świetny
                system odsłuchowy, rewelacyjny zbiór urządzeń peryferyjnych,
                mikrofonów oraz instrumentów. Live room jest idealnym miejscem
                do nagrań dużych rockowych bębnów, kwartetów smyczkowych oraz
                przestrzennych wokali. Z wielką chęcią pomożemy przy bookingu
                noclegu.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="pb-20 bg-zinc-00 -mt-24 overflow-hidden relative"
        style={{ height: '300px' }}>
        <div className="gradient gradient-1 top"></div>
        <div className="gradient gradient-2 top"></div>
      </section>
      <section className="pb-20 mt-24 overflow-hidden relative">
        <div className="container mx-auto px-4">
          <h2 className="text-5xl font-semibold mb-8 text-center">
            ZESPÓŁ SHR
          </h2>
          <h3 className="text-3xl font-semibold mb-8 text-center">
            W składzie osobowym studia jest producent pracujący dla wytwórni
            Atlantic Records (Warner London).
          </h3>
        </div>
      </section>
      <section className="pb-20 overflow-hidden relative">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-9/12 px-4">
              <p className="text-4xl font-semibold mb-8">
                <strong>Dominik Szumny</strong> - właściciel, producent i
                realizator, absolwent Middle Tennessee State University oraz
                Belmont University, multiinstrumentalista (fortepian, gitara,
                bas). Wykładowca Akademii Realizacji Dźwięku w Gdyni oraz
                Polsko-Japońskiej Akademii Technik Komputerowych.
              </p>
            </div>
            <div className="w-full md:w-3/12 px-4">
              <img src={dominik} />
            </div>
          </div>
        </div>
      </section>
      <section className="pb-20 pt-20 overflow-hidden relative main-section">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-3/12 px-4">
              <img src={adrian} />
            </div>
            <div className="w-full md:w-9/12 px-4">
              <p className="text-4xl font-semibold mb-8 text-white">
                <strong>Adrian Soliwoda</strong> - co-producent i realizator,
                manager studia, absolwent Akademii Dziennikarstwa i Realizacji
                Dźwięku w Warszawie oraz Akademii Realizacji Dźwięku w Gdyni,
                multiinstrumentalista (gitara oraz bas).
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-20 pb-20 overflow-hidden relative">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-9/12 px-4">
              <p class="text-4xl font-semibold mb-8">
                <strong>Martin Polak</strong> - co-producent, gitarzysta,
                producent dlaAbbey Road, Atlantic Records, absolwent
                Katowickiej Akademii Muzycznej w klasie Gitary jazzowej.
              </p>
            </div>
            <div class="w-full md:w-3/12 px-4">
              <img src={marcin} />
            </div>
          </div>
        </div>
      </section>
      <ParticlesSction />
    </div>
  );
};

export default About;
