import './footer.scss';
import logo from './../../assets/img/logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footer'>
      <section className='pt-20 pb-20 max-md:pt-0 mt-24 overflow-hidden relative'>
        <div className='container mx-auto px-4'>
          <div className='flex flex-row flex-wrap mx-auto'>
            <div className='pt-6 w-full md:w-4/12 px-8 text-left footer-logo-wrapper'>
              <img src={logo} alt='SHR' />
            </div>
            <div className='pt-6 w-full md:w-4/12 px-8 text-left md:pl-16 lg:pl-32'>
              <h3 className='text-white text-5xl mb-4'>Menu</h3>
              <div className='footer-menu-wrapper grid'>
                <Link to="/" className='nav-link text-xl mb-2 '>Start</Link>
                <Link to="cennik" className='nav-link text-xl mb-2 '>Cennik</Link>
                <Link to="sprzet" className='nav-link text-xl mb-2 '>Sprzęt</Link>
                <Link to="o-nas" className='nav-link text-xl mb-2 '>O nas</Link>
                <Link to="edukacja" className='nav-link text-xl mb-2 '>Edukacja</Link>
                <Link to="shr-events" className='nav-link text-xl mb-2'>Shr events</Link>
                <Link to="kontakt" className='nav-link text-xl mb-2 '>Kontakt</Link>
              </div>
            </div>
            <div className='pt-6 w-full md:w-4/12 px-8 text-left'>
              <h3 className='text-white text-5xl mb-4'>Kontakt</h3>
              <p className='text-xl mb-2 text-white'>
                Powstań Chłopskich 10, Gdynia, Poland
              </p>
              <p className='text-xl mb-2 text-white'>shargaroth@gmail.com</p>
              <p className='text-xl mb-2 text-white'>Dominik: +48 694573532</p>
              <p className='text-xl mb-2 text-white'>Adrian: +48 883770025</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
