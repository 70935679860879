import BlackButton from '../BlackButton';
import './imageBgSection.scss';

const ImageBgSection = () => {
  return (
    <section className="image-bg-scetion relative">
      <div className="text-wrap flex flex-col min-w-0 break-words w-4/5 lg:w-3/5 xl:w-2/5 shadow-lg shadow-slate-700 text-center centered-div px-4 lg:px-8 py-8">
        <h3 className="text-5xl font-semibold mb-8">Edukacja</h3>
        <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-black">
          Nasze studio dysponuje wszechstronnym zapleczem sprzętowym. Posiadamy
          szeroką gamę mikrofonów, gitar, wzmacniaczy oraz duży outboard.
          Ponadto mamy dostęp do niezliczonej liczby instrumentów wirtualnych
          oraz pluginów w programie daw. Cała lista dostępnego sprzętu znajduje
          się w zakładce Sprzęt.
        </p>
        <BlackButton text="Edukacja" link="edukacja" />
      </div>
    </section>
  );
};

export default ImageBgSection;
