import './ParticlesSection.scss';
import BlackButton from '../BlackButton';
import particlesConfig from '../particles-config';
import ParticlesBg from '../ParticlesBg';

const ParticlesSction = () => {
  return (
    <section className="particles min-h-full relative">
      <ParticlesBg />
      <div className="flex flex-col min-w-0 break-words bg-white w-4/5 lg:w-3/5 xl:w-2/5 shadow-lg shadow-slate-700 text-center centered-div px-4 lg:px-8 py-8">
        <div className="md:px-4 md:py-5 flex-auto">
          <h3 className="text-5xl font-semibold mb-8">Skontaktuj się z nami</h3>
          <p className="mt-2 mb-4 text-gray-600">
            Jeśli jesteś zainteresowany zarezerwowaniem terminu w studiu,
            potrzebujesz więcej informacji lub chcesz po prostu pogadać,
            skontaktuj się z nami:
          </p>
          <p className="mt-2 mb-4 md:mb-0 text-gray-600">
            Dominik: <span className="block md:inline">+48 694573532</span> (PL and international booking)
          </p>
          <p className="mb-4 text-gray-600">
            Adrian: <span className="block md:inline">+48 883770025</span> (PL and international booking)
          </p>
          <BlackButton text="Kontakt" link="kontakt" />
        </div>
      </div>
    </section>
  );
};

export default ParticlesSction;
