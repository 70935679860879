import ParticlesSction from '../components/ParticlesSection/ParticlesSction';
import SHRForm from '../components/form/Form';

const Contact = () => {
  return (
    <div>
      <section
        id="o-nas-1"
        class="pb-10 pt-60 pb-80 -mb-48 overflow-hidden relative z-10 main-section">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto text-center">
              <h1 class="text-xxxl font-semibold text-center">Kontakt</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-10 pt-60 -mt-24 overflow-hidden relative z-10 main-section">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg cutted-bottom">
                <div class="px-16 py-16 flex-auto">
                  {/* <div class="text-white p-5 text-center inline-flex items-center justify-center w-24 h-24 mb-5 shadow-lg rounded-full bg-white">
                    <img src="./assets/img/concert.png" alt="realicja-live" />
                  </div> */}
                  <h2 class="text-5xl font-semibold mb-8 text-center">
                    Zadzwoń do nas
                  </h2>
                  <p className="mt-2 mb-4 text-gray-600">
                    Jeśli jesteś zainteresowany zarezerwowaniem terminu w
                    studiu, potrzebujesz więcej informacji lub chcesz po prostu
                    pogadać, skontaktuj się z nami:
                  </p>
                  <p className="mt-2 text-gray-600">
                    Dominik: +48 694573532 (PL and international booking)
                  </p>
                  <p className="mb-4 text-gray-600">
                    Adrian: +48883770025 (PL and international booking)
                  </p>
                </div>
              </div>
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg cutted-bottom mt-4">
                <div class="px-16 py-16 flex-auto">
                  {/* <div class="text-white p-5 text-center inline-flex items-center justify-center w-24 h-24 mb-5 shadow-lg rounded-full bg-white">
                    <img src="./assets/img/concert.png" alt="realicja-live" />
                  </div> */}
                  <h2 class="text-xl font-semibold mb-8 text-center">
                    Informacja o ochronie danych osobowych
                  </h2>
                  <p className="mt-2 mb-4 text-gray-600">
                    Zgodnie z art.13 ogólnego rozporządzenia o ochronie danych
                    osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE : 119 z
                    04.05.2016) informujemy że:
                  </p>
                  <p className="mt-2 mb-4 text-gray-600">
                    1) administratorem Pani/Pana danych osobowych jest SHR
                    STUDIOS Dominik Szumny z siedzibą w Gdyni.
                  </p>
                  <p className="mt-2 mb-4 text-gray-600">
                    2) kontakt z administratorem danych -
                    dszumny.shrstudios@gmail.com
                  </p>
                  <p className="mt-2 mb-4 text-gray-600">
                    3) Pani/Pana dane osobowe przetwarzane będą w celach
                    marketingowych (e-mail, sms), rezerwacji terminu oraz
                    realizacji umowy jak również archiwizacji. Podstawą do
                    przetwarzania tych danych jest art.6 ust. 1 lit f RODO
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-7/12 px-4 -mr-60 ml-auto">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9270.090890994645!2d18.5476275!3d54.4890023!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fda194416f324b%3A0x892aaf2b24882ed8!2sSHR%20Studios%20Dominik%20Szumny!5e0!3m2!1spl!2spl!4v1689773933286!5m2!1spl!2spl"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-20 pt-20 overflow-hidden relative">
        <div class="container mx-auto px-4">
          <h2 class="text-5xl font-semibold mb-8 text-center">Napisz do nas</h2>
          <SHRForm />
        </div>
      </section>
    </div>
  );
};

export default Contact;
