import ParticlesSction from '../components/ParticlesSection/ParticlesSction';

const Priceing = () => {
  return (
    <div>
      <section id="o-nas-1" class="pb-10 pt-60 pb-80 -mb-48 overflow-hidden relative z-10 main-section">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto text-center">
              <h1 class="text-xxxl font-semibold text-center">Cennik</h1>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-40 pb-20 mt-24 overflow-hidden relative">
        <div class="container mx-auto px-4">
          <div class="flex flex-row flex-wrap mx-auto">
            <div class="md:w-3/12"></div>
            <div class="pt-6 w-full md:w-6/12 px-8 col-start-3 text-centner">
              <div class="relative flex flex-col min-w-0 break-words bg-zinc-300 w-full mb-8 shadow-lg cutted-bottom text-centner">
                <div class="px-4 py-5 flex-auto text-center">
                  {/* <div class="text-white p-5 text-center inline-flex items-center justify-center w-24 h-24 mb-5 shadow-lg rounded-full bg-white">
                    <img
                      src="./assets/img/music-equalizer.png"
                      alt="mix&master"
                    />
                  </div> */}
                  <p class="text-xl mb-2">
                    Podstawowa stawka godzinowa to <strong>150 zł</strong> / godzinę
                  </p>
                  <p class="text-xl mb-2">
                    Dzień pracy w studiu z realizatorem od
                    <strong> 1000 zł</strong> (8 godzin)
                  </p>
                  <p class="text-xl mb-2">W przypadku większych projektów stawki ustalane indywidualnie.</p>
                </div>
              </div>
            </div>
            <div class="md:w-3/12"></div>
            <div class="md:w-3/12"></div>
            <div class="pt-6 w-full md:w-6/12 px-8 col-start-3 text-centner">
              <div class="relative flex flex-col min-w-0 break-words bg-zinc-300 w-full mb-8 shadow-lg cutted-bottom text-centner">
                <div class="px-4 py-5 flex-auto text-center">
                  {/* <div class="text-white p-5 text-center inline-flex items-center justify-center w-24 h-24 mb-5 shadow-lg rounded-full bg-white">
                    <img src="./assets/img/microphone.png" alt="nagrania" />
                  </div> */}
                  <p class="text-xl mb-2">
                    Nagranie z trenerem wokalnym - 230 zł za godzinę. (W trakcie sesji nagraniowej trener doradzi i pomoże w jak najlepszym wykonaniu
                    utworu, a po zakończeniu sesji podpowie nad czym należy jeszcze popracować i zada odpowiednie ćwiczenia. Sesje z trenerem dostępne
                    głównie w godzinach wieczornych oraz w weekendy).
                  </p>
                </div>
              </div>
            </div>
            <div class="md:w-3/12"></div>
            <div class="md:w-2/12"></div>
            <div class="pt-6 w-full md:w-8/12 px-8 text-center">
              <div class="relative flex flex-col min-w-0 break-words bg-zinc-300 w-full mb-8 shadow-lg cutted-bottom">
                <div class="px-4 py-5 flex-auto">
                  {/* <div class="text-white p-5 text-center inline-flex items-center justify-center w-24 h-24 mb-5 shadow-lg rounded-full bg-white">
                    <img
                      src="./assets/img/music-equalizer.png"
                      alt="mix&master"
                    />
                  </div> */}
                  <h6 class="text-xl mb-2 font-semibold">Miks + mastering</h6>
                  <p class="text-xl mb-2">
                    do 5 ścieżek - <strong>250 zł</strong> (In the Box) / <strong>300 zł</strong> (przy użyciu analogowej konsolety oraz outboardu)
                  </p>
                  <p class="text-xl mb-2">
                    do 10 ścieżek - <strong>350 zł</strong> (In the Box) / <strong>400 zł</strong> (przy użyciu analogowej konsolety oraz outboardu)
                  </p>
                  <p class="text-xl mb-2">
                    do 24 ścieżek - <strong>550 zł</strong> (In the Box) / <strong>650 zł</strong> (przy użyciu analogowej konsolety oraz outboardu)
                  </p>
                  <p class="text-xl mb-2">Powyżej 24 ścieżek cena ustalana indywidualnie. Przy większych zleceniach ceny ustalane indywidualnie</p>
                </div>
              </div>
            </div>
            <div class="md:w-2/12"></div>
            <div class="md:w-3/12"></div>
            <div class="pt-6 w-full md:w-6/12 px-8 text-center">
              <div class="relative flex flex-col min-w-0 break-words bg-zinc-300 w-full mb-8 shadow-lg cutted-bottom">
                <div class="px-4 py-5 flex-auto">
                  {/* <div class="text-white p-5 text-center inline-flex items-center justify-center w-24 h-24 mb-5 shadow-lg rounded-full bg-white">
                    <img src="./assets/img/concert.png" alt="realicja-live" />
                  </div> */}
                  <h6 class="text-xl font-semibold">Mastering</h6>
                  <ul class="shr-list">
                    <p class="text-xl mb-2">
                      standard - <strong>150 zł</strong> za utwór
                    </p>
                    <p class="text-xl mb-2">
                      standard M/S - <strong>200 zł</strong> za utwór
                    </p>
                    <p class="text-xl mb-2">
                      STEM Mastering - <strong>250 zł</strong> za utwór
                    </p>
                  </ul>
                </div>
              </div>
            </div>
            <div class="md:w-3/12"></div>
            <div class="md:w-3/12"></div>
            <div class="pt-6 w-full md:w-6/12 px-8 text-center">
              <div class="relative flex flex-col min-w-0 break-words bg-zinc-300 w-full mb-8 shadow-lg cutted-bottom">
                <div class="px-4 py-5 flex-auto">
                  {/* <div class="text-white p-5 text-center inline-flex items-center justify-center w-24 h-24 mb-5 shadow-lg rounded-full bg-white">
                    <img src="./assets/img/concert.png" alt="realicja-live" />
                  </div> */}
                  <h6 class="text-xl font-semibold">Dodatkowe usługi</h6>
                  <p class="text-xl mb-2">
                    Edycja i równanie perkusji - od <strong>100 zł</strong> za utwór
                  </p>
                  <p class="text-xl mb-2">
                    Strojenie wokali - od <strong>50 zł</strong> za ścieżkę
                  </p>
                  <p class="text-xl mb-2">
                    Podstawowa edycja ścieżek - od <strong>100 zł</strong> za utwór
                  </p>
                  <p class="text-xl mb-2">
                    Ghostwrite’ing - od <strong>500 zł</strong> za utwór
                  </p>
                  <p class="text-xl mb-2">
                    Magazynowanie urządzeń - <strong>100 zł</strong> sztuka/dziennie
                  </p>
                </div>
              </div>
            </div>
            <div class="md:w-3/12"></div>
            <div class="md:w-3/12"></div>
            <div class="pt-6 w-full md:w-6/12 px-8 text-center">
              <div class="relative flex flex-col min-w-0 break-words bg-zinc-300 w-full mb-8 shadow-lg cutted-bottom">
                <div class="px-4 py-5 flex-auto">
                  {/* <div class="text-white p-5 text-center inline-flex items-center justify-center w-24 h-24 mb-5 shadow-lg rounded-full bg-white">
                    <img src="./assets/img/concert.png" alt="realicja-live" />
                  </div> */}
                  <h6 class="text-xl font-semibold">Preampy oraz Channel Stripe</h6>
                  <p class="text-xl mb-2">Aranż - od 1200 zł za utwór</p>
                  <p class="text-xl mb-2">Kompozycja + aranż - od 3000 zł za utwór</p>
                  <p class="text-xl mb-2">Kompozycja + tekst + aranż - od 3500 zł za utwór</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ParticlesSction />
    </div>
  );
};

export default Priceing;
