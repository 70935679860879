import './scatterGallery.scss';
import amps from './img/amps.jpeg';
import guitars from './img/guitars.jpeg';
import mikser from './img/mikser.jpeg';
import monitors from './img/moniors.jpeg';
import outboard from './img/outboard.jpeg';
import studio from './img/studio.jpeg';

const ScatterGallery = () => {
  return (
    <div className='scatterGallery'>
      <ul>
        <li>
          <img src={amps} alt="" />
        </li>
        <li>
          <img src={guitars} alt="" />
        </li>
        <li>
          <img src={mikser} alt="" />
        </li>
        <li>
          <img src={monitors} alt="" />
        </li>
        <li>
          <img src={outboard} alt="" />
        </li>
      </ul>
    </div>
  );
};

export default ScatterGallery;
