import ParticlesSction from '../components/ParticlesSection/ParticlesSction';
import kikut from '../img/kikut.jpeg';
import kkz1 from '../img/KKZ/kkz1.jpg';
import kkz2 from '../img/KKZ/kkz2.jpg';
import kkz3 from '../img/KKZ/kkz3.jpg';
import kkz4 from '../img/KKZ/kkz4.jpg';
import kkz5 from '../img/KKZ/kkz5.jpg';
import kkz6 from '../img/KKZ/kkz6.jpg';
import kkz7 from '../img/KKZ/kkz7.jpg';
import kkz8 from '../img/KKZ/kkz8.JPG';
import kkz9 from '../img/KKZ/kkz9.JPG';
import kkz10 from '../img/KKZ/kkz10.png';
import kkz11 from '../img/KKZ/kkz11.jpg';

const Education = () => {
  return (
    <div>
      <section
        id="o-nas-1"
        class="pb-10 pt-60 pb-80 -mb-48 overflow-hidden relative z-10 main-section">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto text-center">
              <h1 class="text-xxxl font-semibold text-center">Edukacja</h1>
            </div>
          </div>
        </div>
      </section>
      <section
        id="o-nas-2"
        class="pb-10 pt-60 -mt-24 overflow-hidden relative z-10 main-section">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-7/12 px-4 mr-auto ml-auto">
              <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-white">
                SHR STUDIOS znajduje się w spokojnej willowej dzielnicy Gdyni w
                Orłowie 400 metrów od morza w centrum Trójmiejskiego Parku
                Krajobrazowego. Na studio składają się trzy wyizolowane
                pomieszczenia zaadaptowane akustycznie.
              </p>
              <p class="text-lg font-light leading-relaxed mt-0 mb-8 text-white">
                Studio istnieje od 2011 roku. Nasze produkcje grane są między
                innymi w stacjach radiowych takich jak Eska, RMF FM, Radio Zet.
              </p>
              <p class="text-lg font-light leading-relaxed mt-0 mb-8 text-white">
                Studio wyposażone jest w konsoletę Amek-Neve 48ch, świetny
                system odsłuchowy, rewelacyjny zbiór urządzeń peryferyjnych,
                mikrofonów oraz instrumentów. Live room jest idealnym miejscem
                do nagrań dużych rockowych bębnów, kwartetów smyczkowych oraz
                przestrzennych wokali. Z wielką chęcią pomożemy przy bookingu
                noclegu.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        class="pb-20 bg-zinc-00 -mt-24 overflow-hidden relative"
        style={{ height: '300px' }}>
        <div class="gradient gradient-1 top"></div>
        <div class="gradient gradient-2 top"></div>
      </section>
      <section class="pb-20 overflow-hidden relative">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-9/12 px-4">
              <h2 class="text-5xl font-semibold mb-8 text-center">
                Masterclass z Kubą „Kikutem” Mańkowskim
              </h2>
              <p class="text-xl font-semibold mb-8">
                Jakub „Kuba” Mańkowski (ur. 27 września 1979 w Gdańsku) – polski
                muzyk, kompozytor, wokalista, autor tekstów i
                multiinstrumentalista, a także producent muzyczny, inżynier
                dźwięku i aranżer. Autor muzyki do filmów i spektakli
                teatralnych. Założyciel i właściciel gdyńskiego studia nagrań
                Sounds Great Promotion. Lider metalowego zespołu Pneuma.
                Współpracował z takimi artystami jak Patrycja Markowska,
                Behemoth, Czesław Mozil, Mietek Szcześniak czy Piotr Kupicha,
                Sylwia Grzeszczak, Justyna Steczkowska, Jordan Rudess.
                Współtworzył także krótkotrwały projekt Rio Boss wraz z Liberem
                i Sylwią Grzeszczak.
              </p>
              <p class="text-xl font-semibold mb-8">
                Kikut prowadzi masterclassy z miksu, procesu nagrywania
                perkusji, gitar, wokali.
              </p>
            </div>
            <div class="w-full md:w-3/12 px-4">
              <img src={kikut}/>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-20 pt-20 overflow-hidden relative main-section">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-12/12 px-4">
              <h2 class="text-5xl font-semibold mb-8 text-center text-white">
                Kursy Kwalifikacji Zawodowych
              </h2>
              <p class="text-xl font-semibold mb-8 text-white">
                Kursy Kwalifikacji Zawodowych realizowane w Akademii Realizacji
                Dźwięku w Gdyni, Warszawie, Jaworznie oraz Wrocławiu. Są to
                około roczne dwa kursy - KKZ Nagłośnienie, KKZ Nagrania. Zajecia
                w kazdym miescie ustalane są indywidualnie z grupami, przewaznie
                sa tylko w tygodniu wieczorami. Kursy sa bezpłatne. Kwalifikacje
                uzyskane po kursie to AUD 06 oraz 07 czyli technik obsługi sceny
                oraz technik realizacji nagłośnień jak również AUD 08 oraz 09
                czyli technik montażu dźwięku. Na kursie AUD 06 oraz 07 w
                przypadku Gdyni pracujemy na konsoletach Digico, Midas Pro,
                Wing, X32, SQ, nagłośnieniu Outline oraz Nexo. Na kursie AUD 08
                oraz 09 w przypadku Gdyni pracujemy na komputerach Mac Mini M1
                wraz z oprogramowaniem Logic Pro X, Studio One, Pro Tools. Plus
                pakiet pluginow firm zewnętrznych. Oprócz pracy w laboratorium
                która odbywa się podczas pierwszego semestru, kolejny semestr
                spędzamy w SHR Studios.
              </p>
            </div>
            <div class="flex flex-wrap items-center">
              <div class="w-full md:w-3/12 px-4">
                <img src={kkz1} />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src={kkz2} />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src={kkz3} />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src={kkz4} />
              </div>
            </div>
            <div class="flex flex-wrap items-center pt-8">
              <div class="w-full md:w-3/12 px-4">
                <img src={kkz5} />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src={kkz9} />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src={kkz7} />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src={kkz8} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-20 pb-20 overflow-hidden relative">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-9/12 px-4">
              <h2 class="text-5xl font-semibold mb-8 text-center">
                Roczny Kurs Realizacji Nagrań SHR
              </h2>
              <p class="text-xl font-semibold mb-8">
                Roczny kurs produkcji i realizacji nagran SHR w grupach 4
                osobowych na którym kształcimy adeptów sztuki realizacji dźwięku
                jak prawidłowo wyprodukować utwór od a do z. Od nagran perkusji,
                przez edycje, bas, gitary, wokale, instrumenty klawiszowe,
                kwartet smyczkowy itd.
              </p>
            </div>
            <div class="w-full md:w-3/12 px-4">
              <img src="/assets/img/martin.jpeg" />
            </div>
            <div class="flex flex-wrap items-center">
              <div class="w-full md:w-3/12 px-4">
                <img src="./assets/img/adi.png" />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src="./assets/img/adi.png" />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src="./assets/img/adi.png" />
              </div>
              <div class="w-full md:w-3/12 px-4">
                <img src="./assets/img/adi.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ParticlesSction />
    </div>
  );
};

export default Education;
