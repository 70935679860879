import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Slide1 from './img/1.jpg';
import Slide2 from './img/2.jpg';
import Slide3 from './img/3.jpg';
import Slide4 from './img/4.JPG';
import Slide5 from './img/5.JPG';
import Slide6 from './img/6.JPG';
import Slide7 from './img/7.JPG';
import Slide8 from './img/8.JPG';
import Slide9 from './img/9.JPG';
import Slide10 from './img/10.JPG';
import Slide11 from './img/11.jpg';
import Slide12 from './img/12.JPG';
import Slide13 from './img/13.JPG';
import Slide14 from './img/14.JPG';
import Slide15 from './img/15.jpg';

const Slider = () => {
  return (
    <Carousel infiniteLoop={true} autoPlay={true} dynamicHeight={true} showThumbs={false} style={{height: '100vh'}} autoFocus={true}>
      <div>
        <img src={Slide1} />
      </div>
      <div>
        <img src={Slide2} />
      </div>
      <div>
        <img src={Slide3} />
      </div>
      <div>
        <img src={Slide4} />
      </div>
      <div>
        <img src={Slide5} />
      </div>
      <div>
        <img src={Slide6} />
      </div>
      <div>
        <img src={Slide7} />
      </div>
      <div>
        <img src={Slide8} />
      </div>
      <div>
        <img src={Slide9} />
      </div>
      <div>
        <img src={Slide10} />
      </div>
      <div>
        <img src={Slide11} />
      </div>
      <div>
        <img src={Slide12} />
      </div>
      {/* <div>
        <img src={Slide13} />
      </div> */}
      <div>
        <img src={Slide14} />
      </div>
      <div>
        <img src={Slide15} />
      </div>
    </Carousel>
  );
};

export default Slider;
