import './header.scss';
import logo from './../../assets/img/logo.png';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const Header = () => {

    useEffect(() => {
        console.log('slider')
     })

  return (
    <div className="header">
      <div className="container mx-auto px-4 py-1">
        <div className="flex wrapper">
          <img className="logo" src={logo} />
          <div className='flex'>
            <Link to="/" className='nav-link text-xl'>Home</Link>
            <Link to="cennik" className='nav-link text-xl'>Cennik</Link>
            <Link to="sprzet" className='nav-link text-xl'>Sprzęt</Link>
            <Link to="o-nas" className='nav-link text-xl'>O nas</Link>
            <Link to="edukacja" className='nav-link text-xl'>Edukacja</Link>
            <Link to="shr-events" className='nav-link text-xl'>Shr events</Link>
            <Link to="kontakt" className='nav-link text-xl'>Kontakt</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
